import React, { useMemo, useCallback } from 'react';
import { Formik } from 'formik';

import * as Dates from 'core/helpers/Dates';

const validate = (values, searchType) => {
  const { startDate, endDate } = values.travelDates || {};
  const { year, make, model } = values.towingVehicle || {};

  const errors = {};

  if ((startDate && !endDate) || (!startDate && endDate)) {
    errors.travelDates = 'Must choose both pickup and dropoff dates.';
  }

  if (searchType === 'towables') {
    if (year && !!(!make || !model)) {
      errors.towingVehicle = 'Must choose make and model.';
    }

    if (year && make && !model) {
      errors.towingVehicle = 'Must choose model.';
    }
  }

  return errors;
};

const useInitialValues = ({
  numberOfTravelers,
  destination = {},
  travelDates = {},
  towingVehicle = {},
}) =>
  useMemo(() => {
    const dest = destination || {};
    const dates = travelDates || {};
    const vehicle = towingVehicle || {};

    return {
      destination: {
        location: dest.location || '',
        city: dest.city || '',
        state: dest.state || '',
        country: dest.country || '',
        lat: dest.lat || '',
        lng: dest.lng || '',
        place_id: dest.place_id || '',
      },
      travelDates: Dates.maybeValidDates(dates),
      numberOfTravelers: numberOfTravelers || 1,
      towingVehicle: {
        year: vehicle.year || '',
        make: vehicle.make || '',
        model: vehicle.model || '',
        maxTowingCapacity: vehicle.maxTowingCapacity || '',
      },
    };
  }, [destination, travelDates, numberOfTravelers, towingVehicle]);

function SearchForm(props) {
  const {
    onChange,
    onSubmit,
    inputs: InputsComponent,
    destination,
    travelDates,
    numberOfTravelers,
    towingVehicle,
    submitOnChange,
    type,
    searchType,
    ...restProps
  } = props;
  const formClass = `${type}__Form`;
  const initialValues = useInitialValues({
    destination,
    travelDates,
    numberOfTravelers,
    towingVehicle,
  });

  const getChangeHandler = useCallback(
    context => async (field, value) => {
      // The DatePickerModal is not a formik field.
      // It returns an object with structure { startDate: date, endDate: date } and no associated `field`
      // So here { field: { startDate: date, endDate: date }, value: undifined }
      // We need { field: "travelDates", value: { startDate: date, endDate: date } }
      // Gross workaround until we refactor
      if (field?.startDate && field?.endDate) {
        context.setFieldValue('travelDates', field);
      }

      context.setFieldValue(field, value);

      const errors = await context.validateForm({ [field]: value });

      // Only trigger outside change listener when field is valid
      if (!errors[field]) {
        if (submitOnChange) {
          const { values } = context;
          context.handleSubmit(values);
        } else {
          onChange(field, value);
        }
      }
    },
    [onChange, submitOnChange]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      render={context => {
        const handleChange = getChangeHandler(context);

        return (
          <form
            id="searchForm"
            action="/rv-rental"
            method="get"
            className={formClass}
            onSubmit={context.handleSubmit}
          >
            <InputsComponent
              {...restProps}
              type={type}
              searchType={searchType}
              formClass={formClass}
              handleChange={handleChange}
              submitOnChange={submitOnChange}
            />
          </form>
        );
      }}
    />
  );
}

export default SearchForm;
