import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'just-safe-get';

import SafeRollbar from 'core/helpers/SafeRollbar';

import PlacesAutocompleteSession from '../PlacesAutocompleteSession';

class DestinationInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.inputRef = props.inputRef;
    this.clearSearchInputTextRef = props.clearSearchInputTextRef;
    this.setHasValues = props.setHasValues;
    this.state = {
      text: get(props, 'value.location') || '',
    };
  }

  componentDidMount() {
    if (this.clearSearchInputTextRef) {
      this.clearSearchInputTextRef.current = this.handleTextClear;
    }
  }

  handleTextClear = () => {
    this.setState({ text: '' })
  }

  handleTextChange = text => {
    this.setState({ text });
  };

  inputIsFocused = isFocused => {
    const onFocus = this.props.onFocus || null;
    if (onFocus) {
      this.props.onFocus(isFocused);
    }
  };

  handleFocus = () => {
    this.inputIsFocused(true);
  };

  handleBlur = () => {
    // don't focus or clear text -- if so, the "clear text" X doesn't do anything
    return true;
  };

  handleSelect = (location, placeId) => {
    const { value } = this.props;

    if (!location) {
      return;
    }

    // this prevents the onChange event happening when the location doesn't change
    if (value.location === location && !placeId) {
      return;
    }

    const { name, onChange } = this.props;

    const basicDestination = {
      location,
      place_id: placeId,
    };
    this.setState({ text: location }, () => {
      // We can still submit a search with just location,
      // so even if our async call fails it's seamless to the user
      onChange(name, basicDestination);
      this.inputIsFocused(false);
    });
  };

  // Log error status and clear dropdown when Google Maps API returns an error.
  handleError = (status, clearSuggestions) => {
    if (status === 'OVER_QUERY_LIMIT') {
      SafeRollbar.critical('Google Maps API: OVER_QUERY_LIMIT');
    } else if (status !== 'ZERO_RESULTS') {
      SafeRollbar.error(`Google Maps API: ${status}`);
    }

    clearSuggestions();
  };

  render() {
    const {
      className,
      placeholder,
      type,
      value: { location, lat, lng, place_id },
    } = this.props;

    const { text } = this.state;

    const autocompleteProps = {
      value: text,
      onChange: this.handleTextChange,
      onSelect: this.handleSelect,
      onError: this.handleError,
      debounce: 0,
      highlightFirstSuggestion: true,
      searchOptions: {
        componentRestrictions: {
          country: ['us'],
        },
        types: ['geocode', 'establishment'],
        region: 'us',
      },
    };

    return (
      <div className={className}>
        <input id="lng" type="hidden" value={lng || ''} />
        <input id="lat" type="hidden" value={lat || ''} />
        <input id="place_id" type="hidden" value={place_id || ''} />
        <PlacesAutocompleteSession {...autocompleteProps}>
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            const inputProps = getInputProps({
              id: `${type}__location__AutocompleteInput`,
              title: 'Location',
              className: cx(`${type}__location__Input`, 'form-control', 'form-control-lg'),
              placeholder: location ? null : placeholder,
              onBlur: this.handleBlur,
              onFocus: this.handleFocus,
            });
            // This checks if there are any values in the search box found in inputs.jsx
            if (typeof this.setHasValues === 'function') {
              if(inputProps.value){
                this.setHasValues(true);
              }
              else {
                this.setHasValues(false);
              }
            }
            return (
              <div className={`${type}__location__Autocomplete`}>
                <input ref={this.inputRef} {...inputProps} />
                {!!suggestions.length && (
                  /* eslint-disable global-require */
                  <div
                    className={`${type}__location__AutocompleteContainer`}
                    style={{
                      backgroundImage: `url(${require('images/powered-by-google-white.png')})`,
                    }}
                  >
                    {suggestions.map(suggestion => {
                      const suggestionProps = getSuggestionItemProps(suggestion, {
                        className: cx(`${type}__location__AutocompleteItem`, {
                          active: suggestion.active,
                        }),
                      });

                      return (
                        <div {...suggestionProps} key={suggestion.placeId}>
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocompleteSession>
      </div>
    );
  }
}

const StringOrNumberType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

DestinationInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    location: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    lat: StringOrNumberType,
    lng: StringOrNumberType,
    place_id: PropTypes.string,
  }).isRequired,
};

DestinationInput.defaultProps = {
  className: null,
  onChange: null,
};

export default DestinationInput;
